import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import '../style/title.scss';

const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default function Title() {
  const {
    site: {
      siteMetadata: {
        title,
      },
    },
  } = useStaticQuery(query);
  return (
    <div className="title">
      <h1>
        <Link to="/" className="decoration-none">
          {title}
        </Link>
      </h1>
    </div>
  );
}
