import React from 'react';
import PropTypes from 'prop-types';
import '../style/rectangle.scss';
import RectBase from '../images/rectangle/round_base.svg';
import Round1 from '../images/rectangle/round_1.svg';
import Round2 from '../images/rectangle/round_2.svg';
import Round3 from '../images/rectangle/round_3.svg';
import Round4 from '../images/rectangle/round_4.svg';

function rectangleComponent(round) {
  switch (round) {
    default:
    case 1:
      return Round1;
    case 2:
      return Round2;
    case 3:
      return Round3;
    case 4:
      return Round4;
  }
}

function rectangle(round, classes) {
  if (round <= 0) return null;
  const Comp = rectangleComponent(round);
  return <Comp className={classes} />;
}

export default function Rectangles({ round, className }) {
  const classnames = ['rectangle'];
  if (className !== '') classnames.push(className);
  return (
    <div className={classnames.join(' ')}>
      <RectBase />
      {rectangle(round, 'rectangle-overlay rectangle-overlay-current')}
      {rectangle(round - 1, 'rectangle-overlay')}
    </div>
  );
}

Rectangles.defaultProps = {
  round: 0,
  className: '',
};

Rectangles.propTypes = {
  round: PropTypes.number,
  className: PropTypes.string,
};
