import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

function replaceFunction(_, c) {
  return c ? c.toUpperCase() : '';
}

function camelize(_text) {
  const text = _text.replace(/[-_\s.]+(.)?/g, replaceFunction);
  return text.substr(0, 1).toLowerCase() + text.substr(1);
}

const query = graphql`
  {
    stringsEN: allContentfulLanguageStrings(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        key
        label
      }
    }
    stringsDA: allContentfulLanguageStrings(
      filter: { node_locale: { eq: "da" } }
    ) {
      nodes {
        key
        label
      }
    }
  }
`;

const useLanguageStrings = () => {
  const { pathname } = useLocation();
  const {
    stringsEN: { nodes: languageStringsEN },
    stringsDA: { nodes: languageStringsDA },
  } = useStaticQuery(query);
  const strings = {};
  const languageStrings =
    pathname.indexOf('/en') === 0 ? languageStringsEN : languageStringsDA;
  languageStrings.forEach(({ key, label }) => {
    strings[camelize(key)] = label;
  });
  return strings;
};

export default useLanguageStrings;
