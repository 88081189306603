import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { version } from '../../package.json';
import Font1 from '../fonts/graphik/graphik-light.woff';
import Font2 from '../fonts/graphik/graphik-regular.woff';
import Font3 from '../fonts/graphik/graphik-medium.woff';
import Font4 from '../fonts/graphik/graphik-semibold.woff';
import OgImage from '../images/front.png';

const fonts = [Font1, Font2, Font3, Font4];

const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

function nullToEmpty(str) {
  if (str === null) return '';
  return str;
}

function SEO({
  description,
  lang,
  meta,
  title,
}) {
  const {
    site: {
      siteMetadata: {
        title: siteTitle,
        siteUrl,
      },
    },
  } = useStaticQuery(query);
  const metadata = [
    {
      name: 'headlight-app',
      content: `Q ${version}`,
    },
    {
      name: 'description',
      content: nullToEmpty(description),
    },
    {
      property: 'og:title',
      content: nullToEmpty(title),
    },
    {
      property: 'og:description',
      content: nullToEmpty(description),
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `${siteUrl}${OgImage}`,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: '@webjay',
    },
    {
      name: 'twitter:title',
      content: nullToEmpty(title),
    },
    {
      name: 'twitter:description',
      content: nullToEmpty(description),
    },
  ].concat(meta);
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
      defaultTitle={siteTitle}
      meta={metadata}
    >
      {fonts.map((font) => (
        <link
          key={font}
          href={font}
          rel="preload"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      ))}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  title: '',
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  title: PropTypes.string,
};

export default SEO;
